<template>
    <div>
        <div v-if="loading_imgs == false">
            <h3 class="title">{{content.title}}</h3>
            <p class="sub-title"><span class="source" >发布者:{{content.source}}</span><span class="date">{{content.updateTime}}</span></p>
            <div class="content">
                <div  class="item" v-html="content.content"></div>
            </div>

            <div v-if="content.images.length > 0">
                <div class="block" v-for="(item,index) in content.images" :key="index">
                    <el-image :src="item"></el-image>
                </div>
            </div>
            <div v-if="content.videos.length > 0">
                <div v-for="(item,index) in content.videos" :key="index" style="margin-bottom: 10px;">
                    <video-player class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="videoOptions(item.url,item.img_url)"  ></video-player>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { wexinShare } from '../../assets/common/wecharshare';

    export default {
        name: "articleInfo",
        components:{},
        data(){
            return{
                loading_imgs:true,
                content:[],
                src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
                type : this.$route.query.type,
                id : this.$route.query.id,
            }
        },
        created() {

        },
        mounted: function () {
            this.get_content_info();
        },
        methods:{
            get_content_info(){
                var that = this;
                that.newApi.getShareContent({id:that.id,type:that.type}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.content = ret.data;
                        window.document.title = ret.data.title;
                        if(that.content.title == ""){
                            that.content.title = ret.data.content2;
                        }
                        setTimeout(() => (that.loading_imgs = false), 500);
                        that.weixin();
                    }
                })
            },
            weixin() {
                var that = this;
                //请求微信配置参数接口（获取签名），由后台给接口给
                var urls = window.location.href.split('#')[0];
                that.newApi.getWechatShare({id:that.id,url: urls}).then((res)=>{
                    //微信加签
                    var obj = {
                        appId: res.data.appId,
                        nonceStr: res.data.nonceStr,
                        signature: res.data.signature,
                        timestamp: res.data.timestamp,
                        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
                    };
                    //分享数据，这段主要是为了在hash模式下分享出去的链接不被浏览器截取，保证完全把链接分享出去
                    //var url = window.location.href.split('&')[0];
                    // var shareWxLink = encodeURIComponent(url);加密
                    let shareData = {
                        title: that.content.title, // 分享标题
                        desc: that.content.content2, // 分享描述
                        link: urls,                // 分享链接
                        imgUrl: that.content.cover // 分享图标
                    };
                    //引用
                    wexinShare(obj, shareData);
                })
            },

            videoOptions(url,img){
                var obj = {
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    aspectRatio: '16:9',
                    sources: [{
                        src:url
                    }],
                    fluid: true,
                    poster:img,
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: true,
                        fullscreenToggle: true  //全屏按钮
                    }
                }
                return obj;
            },
        }
    }
</script>

<style  scoped>
    *{margin:0;padding:0}
    body{
        padding: 20px 15px;
    }
    html{
        font-size: 14px;
    }
    .title{
        text-align: center;
        font-size: 1.2rem;
        margin: 20px 0;
    }
    .sub-title{
        position: relative;
        font-size: 0.8rem;
    }
    .sub-title:after{display:block;clear:both;content:"";visibility:hidden;height:0}
    .source{
        float: left;
        line-height: 35px;
    }
    .date{
        float: right;
        line-height: 35px;
    }
    .content{
        text-align: left;
        font-size: 1rem;
        line-height: 1.8

    }
    .content .item{
          width: 100% !important;
      }

    .content .item >>>div{
        width: 100% !important;
    }

    .content .item >>>img{
        margin: 10px 0;
        overflow-x:hidden;
        width: 100% !important;
        height: auto !important;
    }
</style>
